<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.titulo {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 38px;
}
.cambiarColor {
  color: black !important;
}
.cambiarColor2 {
  color: black !important;
  display: flex;
  align-items: center;
}
.cambiarColor:hover {
  color: #2ab2de !important;
}
.cambiarColor2:hover {
  color: #18c378 !important;
}
.icono:hover {
  transition: all 0.5s;
  transform: scale(1.1);
  /* transform: scaleY(1); */
  display: inline-block;

  color: rgb(42, 255, 70) !important;
}
.icono2:hover {
  transition: all 0.5s;
  transform: scale(0.95);
  transform: rotateX(20deg);

  /* transform: scaleY(1); */
  display: inline-block;

  color: rgb(13, 118, 255) !important;
}
.icono3:hover {
  transition: all 0.5s;
  transform: scale(0.95);
  transform: rotateX(20deg);
  /* transform: scaleY(1); */
  display: inline-block;

  color: rgb(255, 177, 42) !important;
}

.iconoLike:focus {
  /* animation-name: bounce; */
  -webkit-animation: bounce 0.5s both;
  animation: bounce 0.5s both;
  /* animation-iteration-count: infinite; */
}
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale(3.15);
    transform: scale(3.15);
  }

  100% {
    transform: translateY(-980px) rotate(600deg);
  }
}
/* @keyframes bounce {
            from {
                transform: translate3d(0, 0, 0);
            }
            to {
                transform: translate3d(0, 60px, 0);
            }
        } */
</style>

<template>
  <!-- CONSULTA DE RESPUESTAS -->
  <v-app>
    <v-container>
      <v-btn
        v-if="esRespuesta"
        icon
        large
        outlined
        color="blue"
        @click="returnTable()"
      >
        <v-icon>mdi-arrow-left</v-icon></v-btn
      >
      <v-divider class="my-2"></v-divider>
      <v-alert
        v-if="msgError != ''"
        border="right"
        colored-border
        type="error"
        elevation="2"
      >
        {{ msgError }}
      </v-alert>
    </v-container>

    <v-container v-if="!esRespuesta">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          label="Buscar pregunta..."
          single-line
          hide-details
          v-model="buscar"
        ></v-text-field>
        <!-- <v-col class="text-right">
          <v-btn depressed color="success" @click="filterSearch()"
            >Buscar
          </v-btn>
        </v-col> -->
      </v-card-title>
      <v-divider class="pa-2"></v-divider>
      <v-row>
        <v-col>
          <v-card elevation="0">
            <div class="text-center titulo">Conversaciones de la comunidad</div>
          </v-card>
        </v-col>
        <v-col>
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                style="border-radius: 100px 100px 100px 100px"
                width="100%"
                max-width="259"
                :elevation="hover ? 20 : 2"
              >
                <v-btn
                  class="text-body-1"
                  dark
                  large
                  rounded
                  color="orange"
                  @click="(nuevaPregunta = true), validaLogin()"
                  >Iniciar una conversación</v-btn
                >
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
      <br />
      <v-col>
        <v-row>
          <v-col v-for="r in filtrador" :key="r._id" cols="12">
            <v-card
              class="mx-auto"
              max-width="1100"
              outlined
              elevation="2"
              shaped
            >
              <v-card class="container" elevation="0">
                <v-row class="container mx-auto text-center">
                  <v-col md="1" xs="1">
                    <v-avatar size="60">
                      <img
                        width="500"
                        src="../../public/desarrollo.png"
                        alt=""
                      />
                    </v-avatar>
                  </v-col>
                  <v-col md="8" xs="3" class="">
                    <div style="margin-top: 5px; text-align: left">
                      <strong>{{ r.nombre_usuarios }}</strong>
                    </div>
                  </v-col>
                  <v-col md="1">
                    <v-badge
                      v-if="r.vistas > 0"
                      :content="r.vistas"
                      :value="r.vistas"
                      color="red"
                    >
                      <v-icon class="icono" style="font-size: 25px"
                        >mdi-eye-outline</v-icon
                      >
                    </v-badge>
                  </v-col>
                  <v-col md="1" v-if="r.likes > 0">
                    <v-badge :content="r.likes" :value="r.likes" color="red">
                      <v-icon class="icono2" style="font-size: 25px"
                        >mdi-thumb-up-outline</v-icon
                      >
                    </v-badge>
                  </v-col>
                  <v-col md="1" xs="1">
                    <v-badge
                      v-if="r.respuestas > 0"
                      :content="r.respuestas"
                      :value="r.respuestas"
                      color="red"
                    >
                      <v-icon class="icono3" style="font-size: 25px"
                        >mdi-comment-text</v-icon
                      >
                    </v-badge>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <br />
                <a
                  @click="crud(r), sumasVistas(r, r.vistas++)"
                  class="text-h5 cambiarColor"
                  style="
                    font-family: 'Trebuchet MS', 'Lucida Sans Unicode',
                      'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                  "
                >
                  {{ r.pregunta }}
                </a>
                <v-card-text>
                  <div
                    v-snip="3"
                    class=""
                    style="font-size: 18px; font-family: Helvetica, sans-serif"
                  >
                    {{ r.descripcion }}
                  </div>
                </v-card-text>
              </v-card>
              <v-card-actions>
                <!-- <v-btn
                              disabled
                              elevation="2"
                              outlined
                              raised
                              rounded
                              label="Activo"

                            >{{r.nombre_usuarios}}</v-btn> -->
                <div class="text-button">
                  Publicado el {{ formatDate(r.created_at) }}
                </div>
                <!-- <v-btn
                              disabled
                              outlined
                              raised
                              rounded
                              label="Activo"

                            >{{r.created_at}}</v-btn> -->

                <!-- <v-switch
                              v-model="ex11"
                              label="Activo"
                              color="primary"
                              value="primary"
                              disabled
                            ></v-switch> -->

                <!-- <v-col class="text-right">
                            <v-btn
                              depressed
                              color="primary"
                              @click="crud(r)"
                            >
                              Ver Comentarios
                            </v-btn>
                            </v-col> -->
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-container>
    <div class="text-center" v-if="!esRespuesta">
      <v-pagination
        elevation="0"
        v-on:input="paginator"
        v-model="page"
        :length="lengthPaginator"
        :total-visible="4"
      ></v-pagination>
      <br />
    </div>

    <!-- PREGUNTA PRINCIPAL -->
    <v-container v-if="esRespuesta">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          label="Buscar respuesta"
          single-line
          hide-details
          v-model="searchDetail"
        ></v-text-field>
        <v-col class="text-right">
          <v-btn depressed color="#18C378" dark @click="filterSearchDetail()"
            >Buscar
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card
        class="mx-auto container"
        max-width="1400"
        elevation="24"
        outlined
        shaped
      >
        <v-row class="container mx-auto">
          <v-col md="1">
            <v-avatar size="60">
              <img width="500" src="../../public/desarrollo.png" alt="" />
            </v-avatar>
          </v-col>
          <v-col md="8" class="">
            <div style="margin-top: 5px; text-align: left">
              <strong>{{ pregunta.nombre_usuarios }}</strong>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <br />

        <a
          class="text-h5 cambiarColor"
          style="
            font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
              'Lucida Sans', Arial, sans-serif;
          "
        >
          {{ pregunta.pregunta }}
        </a>
        <v-card-text>
          <div
            class=""
            style="font-size: 18px; font-family: Helvetica, sans-serif"
          >
            {{ pregunta.descripcion }}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            small
            text
            :disabled="botonLike == false"
            @click="sumarLikes(pregunta, pregunta.likes++)"
            ><v-icon
              tabindex="0"
              class="iconoLike"
              color="cyan"
              style="font-size: 35px"
              >mdi-thumb-up-outline</v-icon
            ></v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            dark
            color="#18C378"
            @click="validaLogin(), activarModalRespuesta(pregunta)"
          >
            responder</v-btn
          >
          <!-- <v-btn
                    disabled
                    elevation="2"
                    outlined
                    raised
                    rounded
                    label="Activo"

                  >{{pregunta.nombre_usuarios}}</v-btn>

                  <v-btn
                    disabled
                    elevation="2"
                    outlined
                    raised
                    rounded
                    label="Activo"

                  >{{pregunta.created_at}}</v-btn> -->

          <!-- <v-switch
                    v-model="ex11"
                    label="Activo"
                    color="primary"
                    value="primary"
                    disabled

                  ></v-switch> -->
        </v-card-actions>
      </v-card>

      <v-container>
        <v-col>
          <v-row>
            <v-col v-for="r in respuestas" :key="r._id" cols="12">
              <v-card
                class="mx-auto"
                max-width="1000"
                outlined
                elevation="2"
                style="border-radius: 0px 40px 40px 40px"
                disabled
              >
                <v-card-text>
                  <div>Respuesta:</div>
                  <div class="text-h6">
                    {{ r.respuesta }}
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-row>
                    <v-col>
                      <v-btn
                        elevation="0"
                        color="orange"
                        raised
                        text
                        class="overline"
                        label="Activo"
                        >De : {{ r.nombre_usuarios }}</v-btn
                      >
                    </v-col>
                    <v-col class="text-right">
                      <v-btn small text raised class="overline" label="Activo"
                        >Publicado el {{ formatDate(r.created_at) }}</v-btn
                      >
                    </v-col>
                    <v-col cols="2" class="">
                      <v-switch
                        style="margin-top: -10px"
                        v-model="ex11"
                        label="Activo"
                        color="orange"
                        value="primary"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
              <v-dialog
                v-model="responder"
                max-width="600"
                width="100%"
                persistent
              >
                <!-- RESPONDER FORO -->
                <v-card class="" color="" outlined>
                  <div class="container text-center">
                        <div class="overline" style="font-size:20px !important; color:#18C378;">¡ COMPARTE T&Uacute; RESPUESTA !</div>
                  </div>
                  <v-card color="white" elevation="6">
                    <br>
                    <v-img class="bordeimagen"  src="../../public/pregunta_respuestav3.png">
                    <br>
                    <br>
                    <v-container style="width: 90%; margin-top:120px;display:flex;">
                      <v-form ref="form" v-model="valid" lazy-validation style=" justify-content:left; align-items:left;">
                        <v-textarea
                        outlined
                          solo
                          name="input-7-4"
                          v-model="respuesta.respuesta"
                          :rules="commentaryRules"
                          label="Respuesta ...."
                        ></v-textarea>

                        <v-btn
                          dark rounded
                          :disabled="!valid"
                          color="#18C378"
                          class="mr-4 overline pa-2"
                          @click="registrarRespuesta(r)"
                          >COMPARTIR MI RESPUESTA</v-btn>

                        <v-btn  rounded color="error" class="mr-4 overline pa-2" @click="reset"
                          >Cancelar
                        </v-btn>
                      </v-form>
                    </v-container>
                    </v-img>
                  </v-card>
                </v-card>
                <!-- RESPONDER FORO -->
              </v-dialog>
            </v-col>
          </v-row>
        </v-col>
      </v-container>
    </v-container>
    <v-dialog width="100%" max-width="700" v-model="nuevaPregunta" persistent>
      <v-card>
        <v-card-title class="justify-center">
          <div class="titulo" style="">¿Cu&aacute;l es tu pregunta?</div></v-card-title
        >
        <v-form class="container">
          <v-row>
            <v-col cols="12" xs="12">
              <v-text-field
                class=""
                dense
                color="orange"
                solo
                v-model="preguntaNueva.pregunta"
                placeholder="Pregunta"
                clearable
                @keydown="buscarPregunta(preguntaNueva.pregunta)"
              ></v-text-field>
              <div class="container">
                <v-row>{{ msgCoincidencia }}</v-row>
                <v-row
                  v-for="item in ventas1"
                  :key="item._id"
                  style="align-items: center"
                >
                  <v-col cols="1">
                    <v-icon color="#18C378"
                      >mdi-star-three-points-outline</v-icon
                    >
                  </v-col>
                  <v-col ms="11">
                    <a
                      @click="
                        crud(item),
                          sumasVistas(item, item.vistas++),
                          (nuevaPregunta = false)
                      "
                      class="cambiarColor2"
                      style="
                        font-family: 'Trebuchet MS', 'Lucida Sans Unicode',
                          'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                        font-size: 13px;
                      "
                    >
                      {{ item.pregunta }}
                    </a>
                    <!-- <v-divider></v-divider> -->
                  </v-col>
                  <!-- <div>
                  </div> -->
                  <!-- <a href="">{{item.pregunta}}</a> -->
                </v-row>
              </div>
            </v-col>
            <v-col md="12">
              <v-textarea
                v-model="preguntaNueva.descripcion"
                rows="5"
                solo
                outlined
                clearable
                color="orange"
                label="Descripción"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn color="error" dark @click="nuevaPregunta = false"
              >cancelar</v-btn
            >
            <v-btn color="#18C378" dark @click="agregarPregunta()">Agregar pregunta</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-app>
  <!-- CONSULTA DE RESPUESTAS -->
</template>
 

<script>
import axios from "axios";
import config from "../json/config.json";

export default {
  data: () => ({
    ex11: [
      "red",
      "indigo",
      "orange",
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "error",
      "red darken-3",
      "indigo darken-3",
      "orange darken-3",
    ],
    buscar: "",
    search: "",
    searchDetail: "",
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Nombre Completo es requerido",
      (v) =>
        (v && v.length <= 100) ||
        "El nombre completo debe tener menos de 50 caracteres",
    ],

    id: "",

    commentary: "",
    commentaryRules: [
      (v) => !!v || "Comentario es requerido",
      (v) =>
        (v && v.length >= 20) ||
        "El comentario debe tener menos de 20 caracteres",
    ],

    esRespuesta: false,
    msgError: "",

    respuestas: [],
    respuesta: {
      numeropregunta: "",
      respuesta: "",
      activo: true,
      calificacion: "10",
      created_by: "",
      nombre_usuarios: "",
    },

    ventas: [],
    ventas1: [],
    accounts: [],

    users: {},
    isLogged: false,

    preguntaGeneral: "",
    //LUIS AGUILERA
    vistaMensaje: 0,
    nuevaPregunta: false,
    preguntaNueva: {
      pregunta: "",
      descripcion: "",
      estado: "Pendiente",
      created_by: "",
      nombre_usuarios: "",
      vistas: 0,
      respuestas: 0,
    },
    responder: true,
    validacionlike: {},
    botonLike: true,
    validacion: {},
    conteoRespuestas: {},
    page: 1,
    lengthPaginator: 0,
    totalPage: 10,
    preguntasPaginador: [],
    msgCoincidencia: "",
  }),

  async created() {
    await this.mostrarTodasPreguntas();
    await this.paginator();
    //  await this.union();
    //  await this.validaLogin();
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.responder = false;
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    returnTable() {
      this.esRespuesta = false;
      this.msgError = "";
    },

    formatDate(dates) {
      if (dates === undefined) return "Error de Fecha";
      try {
        var month = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];
        let date = new Date(Date.parse(dates));
        return `${date.getDate()} de ${
          month[date.getMonth()]
        } del ${date.getFullYear()}`;
      } catch (error) {
        console.log(error);
        return "Error de Fecha";
      }
    },

    async mostrarTodasPreguntas() {
      this.isLoad = true;
      this.ventas = await axios
        .get(config.apiAmoresens + "/foro/get_all", {
          headers: { token: this.$cookie.get("token") },
          data: { filer: {} },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err;
        });
      if (this.ventas.status == "success") {
        this.ventas = this.ventas.data;
        // console.log(this.ventas);
        await this.paginator();
      }
      this.isLoad = false;
    },

    async refresh() {
      await this.mostrarTodasPreguntas();
      this.filterSearch();
    },

    async filterSearch(pregunta) {
      if (pregunta.length > 5) {
        this.ventas1 = {};

        this.isLoad = true;
        this.ventas1 = await axios
          .get(config.apiAmoresens + "/foro/get_question", {
            headers: { token: this.$cookie.get("token") },
            params: { pregunta: pregunta },
          })
          .then((res) => {
            // console.log(res.data);
            return res.data;
          })
          .catch((err) => {
            return err;
          });

        if (this.ventas1.status == "success") {
          this.ventas1 = this.ventas1.data;
        }
        // console.log(this.ventas1, "ventas");
        if (this.ventas1 == "") {
          (this.msgCoincidencia = ""), (this.ventas1 = "");
        } else if (this.ventas1.length >= 1) {
          this.msgCoincidencia =
            "Existen coincidencias similares a tu pregunta :";
        }
        this.isLoad = false;
      } else if (pregunta.length < 5) {
        (this.msgCoincidencia = ""), (this.ventas1 = "");
      }
    },

    async filterSearchDetail() {
      this.respuestas = [];
      //console.log(this.search);

      this.isLoad = true;
      this.respuestas = await axios
        .get(config.apiAmoresens + "/foro/get_all_answer_question", {
          headers: { token: this.$cookie.get("token") },
          params: { respuesta: this.searchDetail },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err;
        });

      if (this.respuestas.status == "success") {
        this.respuestas = this.respuestas.data;
      }
      this.isLoad = false;
    },

    async crud(item) {
      this.msgError = "";
      this.esRespuesta = true;
      this.pregunta = item;
      this.respuesta.numeropregunta = item.numeropregunta;
      //this.preguntaGeneral = item.numeropregunta;
      this.searchDetail = "";
      this.mostrarRespuestasPreguntas(item.numeropregunta);
    },
    /////////////////////// RESPUESTAS //////////////////////
    async mostrarRespuestasPreguntas(item) {
      // console.log(item, "item");
      await this.registrosLikesSocio(item);
      this.isLoad = true;
      this.respuestas = await axios
        .get(config.apiAmoresens + "/foro/get_all_answer", {
          headers: { token: this.$cookie.get("token") },
          params: { numeropregunta: item },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err;
        });

      if (this.respuestas.status == "success") {
        this.respuestas = this.respuestas.data;
        // console.log(this.respuestas);
      } else {
        // this.isLogged = false;
        // location.href = "/shop/Login";
      }
      this.isLoad = false;
    },

    async registrarRespuesta(r) {
      this.msgError = "";
      // console.log(JSON.stringify(this.solicitud));
      //this.respuesta.numeropregunta = this.respuesta.numeropregunta;
      this.respuesta.created_by = this.user.ad_user_id;
      this.respuesta.nombre_usuarios = this.user.cpname;

      let result = await axios
        .post(
          config.apiAmoresens + "/foro/add_answer_user",
          this.respuesta,

          { headers: { token: this.$cookie.get("token") } }
        )
        .then((res) => {
          // console.log(res);
          return res;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
      if (result.data.status == "success") {
        result = result.data.data;
        await this.sumasRespuestas(r);
      }
      // console.log(result);
      this.mostrarRespuestasPreguntas(this.respuesta.numeropregunta);
      this.respuesta.respuesta = "";
    },

    async validaLogin() {
      this.user = await axios
        .get(config.apiAmoresens + "/user/userByToken", {
          headers: { token: this.$cookie.get("token") },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err;
        });
      if (this.user.status == "success") {
        this.user = this.user.user;
        this.isLogged = true;
      } else if (this.user.status == "unauthorized") {
        this.isLogged = false;
        location.href = "/shop/Login";
      }
    },
    async agregarPregunta() {
      await this.validaLogin();
      this.preguntaNueva.created_by = this.user.ad_user_id;
      this.preguntaNueva.nombre_usuarios = this.user.cpname;
      this.preguntaNueva.likes = 0;
      const NuevaPregunta = await axios
        .post(config.apiAmoresens + "/foro/add_question", this.preguntaNueva, {
          headers: { token: this.$cookie.get("token") },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          // console.log(err);
          return err;
        });
      if (NuevaPregunta.status == "success") {
        this.nuevaPregunta = false;
      } else {
        this.nuevaPregunta = false;
        this.msgError = "Vuelve a intentarlo mas tarde";
      }
    },
    async sumasVistas(r, vistas) {
      // console.log(vistas +1);
      // console.log(r);
      await axios
        .put(config.apiAmoresens + "/foro/sumarVista", r, {
          params: { vistas: vistas + 1 },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err;
        });
    },
    async sumarLikes(r, likes) {
      await axios
        .get(config.apiAmoresens + "/foro/verRegistrosLike", {
          headers: { token: this.$cookie.get("token") },
        })
        .then((res) => {
          this.validacionlike = res.data;
          // console.log(this.validacionlike);
        })
        .catch((err) => {
          return err;
        });
      if (this.validacionlike.status == "unauthorized") {
        this.isLogged = false;
        location.href = "/shop/Login";
      } else {
        this.validacionlike = this.validacionlike.data;
        for (let index = 0; index < this.validacionlike.length; index++) {
          this.validacion = this.validacionlike[index];
        }
        if (this.validacion.numeropregunta == r.numeropregunta) {
          this.botonLike = false;
        } else {
          const MeGusta = await axios
            .put(config.apiAmoresens + "/foro/sumarLike", r, {
              headers: { token: this.$cookie.get("token") },
              params: { likes: likes + 1 },
            })
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err;
            });
          if (MeGusta.status == "unauthorized") {
            this.isLogged = false;
            location.href = "/shop/Login";
          } else if (MeGusta.status == "success") {
            this.botonLike = false;
            await axios
              .post(config.apiAmoresens + "/foro/registrarLike", r, {
                headers: { token: this.$cookie.get("token") },
                params: { likes: likes + 1 },
              })
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err;
              });
          }
        }
      }
    },
    async registrosLikesSocio(r) {
      // console.log(r);
      await axios
        .get(config.apiAmoresens + "/foro/verRegistrosLike", {
          headers: { token: this.$cookie.get("token") },
        })
        .then((res) => {
          this.validacionlike = res.data;
          // console.log(this.validacionlike,'validacion');
        })
        .catch((err) => {
          return err;
        });
      if (this.validacionlike.status == "unauthorized") {
        this.isLogged = false;
        //  location.href = "/shop/Login";
      } else {
        this.validacionlike = this.validacionlike.data;
        for (let index = 0; index < this.validacionlike.length; index++) {
          this.validacion = this.validacionlike[index];
          if (this.validacion.numeropregunta == r) {
            this.botonLike = false;
            console.log("Hay registro LIKE");
          } else {
            this.botonLike = true;
          }
        }
      }
    },
    async sumasRespuestas() {
      // console.log(this.sumarRespuestas, "user");
      // console.log(this.sumarRespuestas.respuestas, "respuestas");
      await axios
        .put(
          config.apiAmoresens + "/foro/sumarRespuestas",
          this.sumarRespuestas,
          { params: { respuestas: this.sumarRespuestas.respuestas + 1 } }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err;
        });
      this.responder = false;
    },
    activarModalRespuesta(pregunta) {
      // console.log(pregunta);
      this.sumarRespuestas = pregunta;
      this.responder = true;
    },
    paginator() {
      this.lengthPaginator = Math.ceil(this.ventas.length / this.totalPage);
      this.preguntasPaginador = [];
      let fin =
        this.page * this.totalPage - 1 > this.ventas.length - 1
          ? this.ventas.length - 1
          : this.page * this.totalPage - 1;
      for (
        let index = (this.page - 1) * this.totalPage;
        index <= fin;
        index++
      ) {
        let element = this.ventas[index];
        this.preguntasPaginador.push(element);
        if (index == fin) {
          window.scrollTo(0, 0);
        }
      }
      window.scrollTo(0, 0);
    },
    async buscarPregunta(pregunta) {
      // console.log('pregunta');
      await this.filterSearch(pregunta);
    },
  },
  computed: {
    ventasLength() {
      return this.ventas.length;
    },
    filtrador() {
      return this.preguntasPaginador.filter((post) => {
        return post.pregunta.toLowerCase().includes(this.buscar.toLowerCase());
      });
    },
  },
};
</script>
